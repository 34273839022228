<template>
  <component
    :is="isInteractive ? 'button' : 'div'"
    :class="{
      'lg:col-span-3': useGrid && imageIndex === 0,
      'lg:row-span-2': useGrid && imageIndex === 1 && totalImages === 2,
      'lg:col-span-1': useGrid && totalImages >= 2,
      'row-span-2': useGrid && imageIndex === 0,
      'hidden lg:block': imageIndex > 0,
    }"
    class="relative col-span-full h-full w-full"
    aria-label="View gallery"
    @click="onClick"
  >
    <div v-if="!isInteractive" class="bg-primary-800/40 absolute inset-0" />
    <NuxtImg
      :src="image.filename"
      :alt="image.alt"
      loading="lazy"
      width="1920"
      class="size-full object-cover"
    />
  </component>
</template>

<script setup lang="ts">
import type { MultiassetStoryblok } from '@/types/storyblok'

const props = withDefaults(
  defineProps<{
    imageIndex: number
    useGrid: boolean
    totalImages: number
    image: MultiassetStoryblok[0]
    isInteractive: boolean
  }>(),
  {
    isInteractive: true,
  },
)
const { togglePhotoGallery } = useUIStore()

function onClick(event: Event): void {
  if (props.isInteractive) {
    event.preventDefault()
    togglePhotoGallery(true)
  }
}
</script>
