<template>
  <section
    v-editable="blok"
    :class="{
      'min-h-[45vh] xl:min-h-[60vh]': blok.size === 'fill-screen',
      'min-h-130': blok.size === 'default',
      'min-h-85 lg:min-h-120': blok.size === 'small',
      'bg-white text-white': blok.gallery?.length,
      'text-primary-800 bg-gray-300': !blok.gallery?.length,
      'lg:pb-32': blok.logo?.filename,
      'pb-10': !!communityName,
      'pb-16': !communityName,
    }"
    class="relative flex"
  >
    <div
      v-if="blok.showOverlay"
      class="pointer-events-none absolute inset-0 z-10 bg-black/50"
    />
    <div
      :class="{
        'pointer-events-none': !blok.heading && !blok.subHeading,
      }"
      class="container relative z-20"
    >
      <div
        v-if="
          blok.heading ||
          blok.subHeading ||
          blok.content?.length ||
          blok.badge?.filename
        "
        :class="{
          'items-center': blok.alignment === 'center',
          'items-start': blok.alignment === 'left',
          'items-end': blok.alignment === 'right',
          'pointer-events-none': !blok.heading && !blok.subHeading,
        }"
        class="relative z-10 flex h-full flex-col justify-end gap-8 lg:justify-center"
      >
        <div
          :class="{
            'pt-20 lg:pt-52': blok.logo?.filename,
            'pt-16': !blok.logo,
            'text-left': blok.alignment === 'left',
            'text-center': blok.alignment === 'center',
            'text-right': blok.alignment === 'right',
          }"
          class="flex h-full max-w-4xl flex-col gap-4"
        >
          <figure
            v-if="blok.badge?.filename"
            class="absolute right-0 top-0 w-[5.5rem] lg:w-[7.5rem]"
          >
            <NuxtImg
              :src="blok.badge.filename"
              :alt="blok.badge.alt"
              class="w-full"
            />
          </figure>

          <figure v-if="blok.logo?.filename" class="mb-8">
            <NuxtImg
              :src="blok.logo.filename"
              :alt="blok.logo.alt"
              :class="{
                'mx-auto': blok.alignment === 'center',
              }"
              class="w-full max-w-36 lg:max-w-44"
            />
          </figure>

          <div
            :class="{
              'translate-y-16 transform':
                blok.content?.[0]?.component === 'BlockHeroSearch',
            }"
            class="my-auto"
          >
            <h1
              v-if="blok.heading"
              :class="{
                'font-display': blok.fontStyle === 'display' || !blok.fontStyle,
                'font-sans font-bold': blok.fontStyle === 'regular',
                'text-3xl': blok.fontSize === 'regular',
                'text-3xl lg:text-4xl': blok.fontSize === 'large',
                'max-w-2xl text-3xl lg:text-5xl':
                  blok.fontSize === 'extra-large',
              }"
              class="mx-auto"
            >
              {{ blok.heading }}
            </h1>

            <p
              v-if="blok.subHeading"
              :class="{
                'mx-auto': blok.alignment === 'center',
              }"
              class="max-w-2xl"
            >
              {{ blok.subHeading }}
            </p>
          </div>
        </div>

        <div
          v-if="blok.content?.length"
          :class="{
            'justify-start': blok.alignment === 'left',
            'justify-center': blok.alignment === 'center',
            'justify-end': blok.alignment === 'right',
          }"
          class="mt-auto flex w-full"
        >
          <StoryblokComponent
            v-for="component in blok.content"
            :key="component._uid"
            v-editable="blok"
            :blok="component"
            class="pointer-events-auto"
          />
        </div>
      </div>
    </div>

    <div class="absolute inset-0">
      <template
        v-if="blok.backgroundType === 'video' && blok.backgroundVideo?.filename"
      >
        <video playsinline loop muted autoplay class="size-full object-cover">
          <source
            :src="blok.backgroundVideo?.filename"
            :type="getAssetMimeType(blok.backgroundVideo.filename)"
          />
        </video>
      </template>
      <template
        v-else-if="blok.backgroundType === 'gallery' || !blok.backgroundType"
      >
        <HeroGallery
          v-for="gallery in blok.gallery"
          :key="gallery._uid"
          v-editable="blok"
          :blok="gallery"
          :is-interactive="isGalleryInteractive"
        />
      </template>
    </div>

    <template v-if="!isCommunity && !isProperty">
      <PhotoGallery
        v-for="gallery in blok.gallery"
        :key="gallery._uid"
        :is-open="isPhotoGalleryVisible"
        :images="gallery.galleryItems"
        :gallery-style="gallery.galleryStyle"
        @close="onPhotoGalleryClose"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import type { BlockHeroStoryblok } from '@/types/storyblok'
import { getAssetMimeType } from '@/utils/asset-utils'

const props = defineProps<{ blok: BlockHeroStoryblok }>()
const uiStore = useUIStore()
const { togglePhotoGallery } = uiStore
const { isPhotoGalleryVisible } = storeToRefs(uiStore)
const { story, communityName } = storeToRefs(useCurrentStoryStore())
const isGalleryInteractive = computed(
  () =>
    !props.blok.heading && !props.blok.subHeading && !props.blok.logo?.filename,
)
const isCommunity = computed(() => {
  return story.value?.content?.component === 'ContentTypeCommunity'
})
const isProperty = computed(() => {
  return story.value?.content?.component === 'ContentTypeProperty'
})

function onPhotoGalleryClose() {
  togglePhotoGallery(false)
}
</script>
