<template>
  <div
    v-editable="blok"
    :class="{
      'grid grid-cols-4 grid-rows-2 gap-1': useGrid,
    }"
    class="size-full"
  >
    <HeroGalleryTileImage
      v-for="(image, imageIndex) in images"
      :key="image.id"
      :image-index="imageIndex"
      :total-images="totalImages"
      :use-grid="useGrid"
      :image="image"
      :is-interactive="isInteractive"
    />
  </div>
</template>

<script setup lang="ts">
import type { BlockGalleryStoryblok } from '@/types/storyblok'

const props = withDefaults(
  defineProps<{
    blok: BlockGalleryStoryblok
    isInteractive?: boolean
  }>(),
  {
    isInteractive: true,
  },
)
const totalImages = computed(() => props.blok.galleryItems?.length)
const useGrid = computed(() => {
  return galleryStyle.value === 'grid' && totalImages.value >= 2
})
const images = computed(() => {
  return (
    props.blok.galleryItems?.slice(
      0,
      galleryStyle.value === 'default' ? 1 : 3,
    ) || []
  )
})
const galleryStyle = computed(() => props.blok.galleryStyle)
</script>
